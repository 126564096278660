import React from 'react'
import Layout from '../components/layout'
import FeaturedProjects from '../components/page_blocks/featuredProjects'

const SubmissionPage = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const successfulSubmission = params.get('success')

	return (
		<Layout>
			<div className="article">
				<div className="section text-base text-center">
					{successfulSubmission === 'true' ? (
						<p className="max-w-[60ch] mx-auto">
							Thanks for getting in touch! You'll be hearing from us soon.
						</p>
					) : (
						<div className="max-w-[60ch] mx-auto">
							<p className="mb-8">
								Sorry, it looks like something went wrong while submitting your
								form.
							</p>
							<p>
								Please email us at{' '}
								<a href="mailto:newbiz@fastforward.sh">newbiz@fastforward.sh</a>{' '}
								instead.
							</p>
						</div>
					)}
				</div>
			</div>
			<FeaturedProjects />
		</Layout>
	)
}

export default SubmissionPage
